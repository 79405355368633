import React from "react"
import SEO from "../components/seo"

import styled from "styled-components"
import { Navbar } from "../components/Navbar"
import { QA } from "../components/QA"
import { Text } from "../components/styled/Text"
import { Title } from "../components/styled/Title"
import { SecondaryTitle } from "../components/styled/SecondaryTitle"
import Layout from "../components/layout"

const Body = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 0 24px;
`

const HelpPage = () => (
  <Layout>
    <SEO title="Vanlige spørsmål om Kjør" />
    <Body>
      <Title>Vanlige spørsmål om Kjør</Title>

      <SecondaryTitle>Generelt</SecondaryTitle>

      <QA
        title="Hvordan legger jeg til bilen min?"
        text={[
          "Kjør bruker bilens Bluetooth for å finne ut når du kjører. Før du begynner å bruke Kjør, må du derfor koble telefonen din til bilens Bluetooth.Telefonen er korrekt koblet til bilen hvis musikk du spiller fra telefonen spilles gjennom høyttalerne i bilen.Dersom dette ikke fungerer, sjekk bruksanvisningen til bilen eller telefonen din for å sette opp Bluetooth.",
          "Når Bluetooth-tilkoblingen er på plass, åpner du Kjør. Trykk deretter på den store startknappen midt på skjermen. Kjør viser da en liste over Bluetooth - enheter som er koblet til.Trykk på bilen din for å legge den til og låse telefonen. Hvis listen er tom, kontroller Bluetooth-tilkoblingen.",
        ]}
      />

      <QA
        title="Det har vært en ulykke, kan Kjør bevise at jeg ikke brukte telefonen da ulykken skjedde?"
        text={[
          "I de fleste tilfeller, ja. Kjør loggfører hver låsing og opplåsing til skyen.Loggen kan ikke redigeres, men av hensyn til ditt personvern blir den slettet etter to uker.",
          "Du kan se loggen ved å trykke Profil-knappen i Kjør. Ved behov kan vi også gi deg en digitalt signert kopi av loggen for bruk ovenfor forsikring og lignende. Ta kontakt for hjelp med dette, men husk at heller ikke vi kan hente ut logger lengre enn to uker bakover i tid.",
        ]}
      />

      <QA
        title="Hva skjer om jeg trykker “Nødsituasjon”?"
        text={[
          "Når Kjør har låst telefonen, vises en Nødsituasjon-knapp. Denne gir deg mulighet til å låse opp telefonen for å ringe til nødetatene. Kjør loggfører at du trykket på Nødsituasjon-knappen.",
        ]}
      />

      <SecondaryTitle>Betaling</SecondaryTitle>

      <QA
        title="Appen ber om betaling?"
        text={[
          "Kjør er en abonnementstjeneste, og betaling skjer gjennom Google Play-butikken. Følg veiledningen i appen for å sette opp en betalingsmåte. Vi spanderer den første måneden, slik at du kan forsikre deg om at appen fungerer skikkelig før du betaler.",
        ]}
      />

      <QA
        title="Jeg skal på ferie/ikke bruke bilen på noen måneder. Må jeg betale mens
        jeg er borte?"
        text={[
          "Det trenger du ikke. Bare sett abonnementet på pause fra Profil-siden. For mer informasjon, se Sett abonnementer på pause.",
        ]}
      />

      <QA
        title="Jeg har slettet appen, men blir fortsatt belastet for abonnementet?"
        text={[
          "Siden Kjør kan brukes på flere enheter med samme abonnement, blir ikke abonnementet stoppet automatisk når du avinstallerer Kjør fra en telefon.Du kan alltid stanse abonnementet i Google Play - butikken, se Avslutt abonnementer i Google Play - appen.",
        ]}
      />

      <SecondaryTitle>Tekniske problemer</SecondaryTitle>

      <QA
        title="Hvorfor står det at “Auto-lås er aktivert”? Hvordan blir jeg kvitt
        meldingen?"
        text={[
          "Meldingen vises for å gjøre deg oppmerksom på at Kjør vil låse telefonen når du starter bilen.",
          "Hvis du ikke vil se denne meldingen, trykk og hold på den i et par sekunder.Da vises en meny hvor du kan slå av dette varselet.",
        ]}
      />

      <QA title="Telefonen blir ikke låst når jeg starter bilen?">
        <Text>
          Prøv å åpne Kjør. Hvis telefonen…
          <ul>
            <li>
              …forblir ulåst: Sjekk at telefonen er koblet til bilens Bluetooth,
              se “Hvordan legger jeg til bilen min?”.
            </li>
            <li>
              …låses: Problemet skyldes sannsynligvis at telefonen hindrer kjør
              i å starte automatisk, se “Telefonen min sier at Kjør bruker mye
              batteri?”.
            </li>
          </ul>
        </Text>
      </QA>

      <QA
        title="Hvorfor viser Kjør et rødt utropstegn ved siden av bilen?"
        text={[
          "Utropstegnet betyr at bilen er lagt til i Kjør, men det er ingen Bluetooth - forbindelse til bilen for øyeblikket. Se “Hvordan legger jeg til bilen min?”",
        ]}
      />

      <QA
        title="Telefonen min sier at Kjør bruker mye batteri?"
        text={[
          "Kjør er laget for å ikke bruke batteri, selv om noen telefoner vil påstå at den “alltid kjører”. Mange telefoner kommer med ekstra batterisparingsfunksjoner som ikke er en offisiell del av Android. Disse har en tendens til å rapportere apper for å bruke batteri, uavhengig av hvor mye batteri appene faktisk har brukt.",
          "Vanligvis kan du sette Kjør på en form for unntaksliste. Dette vil sikre at auto - lås fungerer som det skal, samtidig som meldingene om at Kjør bruker batteri blir borte.Instruksjonene for å gjøre dette varierer veldig avhengig av produsent. Besøk Don't kill my app for mer informasjon, og ta gjerne kontakt om du ikke finner ut av problemet.",
        ]}
      />

      <QA
        title="Jeg har stoppet bilen, men telefonen min er fortsatt låst!"
        text={[
          "Kjør låser telefonen så lenge Bluetooth-forbindelsen til bilen er aktiv. Noen ganger kan det ta opp mot et minutt fra du stopper bilen til telefonen oppdager at bilen er slått av.I dette tidsrommet kan heller ikke Kjør vite at du har stoppet bilen.",
          "Hvis det går flere minutter uten at telefonen låses opp, trykk Nødsituasjon - knappen.Dette vil låse opp telefonen.Ta gjerne kontakt hvis du må ty til Nødsituasjon - knappen, så vi kan rette det underliggende problemet som førte til at telefonen ikke ble låst opp automatisk.",
        ]}
      />
    </Body>
  </Layout>
)

export default HelpPage
